import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const graphQLQuery = async (name) => {

    try {
        const response = await API.graphql(graphqlOperation(queries[name]));
        return response.data[name];
    }
    catch (error) {
        let message = '';

        if (!error.errors || !error.errors.length) {
            message = 'No error message provided';
        } else {
            let graphQLError;
            for (graphQLError of error.errors) {
                message = `${message} ${graphQLError.message}`;
            }
        }

        throw new Error(`Could not complete the GraphQL request -${message}`);
    }

}

export default graphQLQuery;