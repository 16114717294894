import { useContext, useEffect } from 'react';
import NonceContext from '../NonceContext';

const useMutationObserverForNonce = () => {
    const nonce = useContext(NonceContext);

    useEffect(() => {
        if (!nonce) {
            return;
        }
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        // Specifically track <style> nodes to apply the nonce
                        if (node.nodeName === 'STYLE' && !node.hasAttribute('nonce')) {
                            node.setAttribute('nonce', nonce);
                        }

                        // Track <script> nodes and apply nonce only to inline scripts (not external ones)
                        if (node.nodeName === 'SCRIPT') {
                            if (!node.hasAttribute('src') && !node.hasAttribute('nonce')) {
                                node.setAttribute('nonce', nonce);
                            } else if (node.hasAttribute('src')) {
                            }
                        }

                        // Handle inline styles directly on elements
                        if (node.hasAttribute && node.hasAttribute('style')) {
                        }
                    }
                });
            });
        });

        // Observe changes in the document head and body for added nodes and attribute changes
        observer.observe(document.head, { childList: true, subtree: true });

        // Clean up the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, [nonce]);

    return null;
};

export default useMutationObserverForNonce;
