import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import questionsReducer from './questions/reducer';
import questionnaireReducer from './questionnaire/reducer';
import resultsReducer from './results/reducer';

const reducers = () =>
    combineReducers({
        form: formReducer,
        questions: questionsReducer,
        questionnaire: questionnaireReducer,
        results: resultsReducer,
    });

export default reducers;
