import {
    QUESTIONS_REQUESTED,
    QUESTIONS_RESOLVED,
    QUESTIONS_ERROR,
} from '../../actions/questions/types';
import {
    STATE_RESOLVING,
    STATE_RESOLVED,
    STATE_ERROR,
} from '../../services/state/types';
import { RESET } from '../../actions/reset/types';
import schema from '../schema';

const initialState = schema.set('questions', null);

/**
*
* The Questions Reducer
*
* Responsible for managing the state and data of the risk questions
*
*/
const questionsReducer = (state = initialState, action) => {

    switch (action.type) {

        case RESET:

            return initialState;

        case QUESTIONS_REQUESTED:

            return state
                .set('status', STATE_RESOLVING)

        case QUESTIONS_RESOLVED:

            return state
                .set('status', STATE_RESOLVED)
                .set('questions', action.payload)

        case QUESTIONS_ERROR:

            return state
                .set('status', STATE_ERROR)
                .set('questions', null)
                .set('error', action.payload)

        default:

            return state;
    }
};

export { initialState };
    
export default questionsReducer;
