// eslint-disable-next-line
/* global __webpack_nonce__ */
import React, { createContext, useEffect } from 'react';
import { generateNonce } from './services/nonceGenerate';

const NonceContext = createContext();

const newNonce = generateNonce();

export const NonceProvider = ({ children }) => {
    useEffect(() => {
        const Region = process.env.REACT_APP_AWS_REGION;

        // Create and append the CSP meta tag
        const cspMetaTag = document.createElement('meta');
        cspMetaTag.httpEquiv = 'Content-Security-Policy';
        cspMetaTag.content = `upgrade-insecure-requests; connect-src 'self' *.appsync-api.${Region}.amazonaws.com cognito-idp.${Region}.amazonaws.com *.auth.${Region}.amazoncognito.com cognito-identity.${Region}.amazonaws.com *.google-analytics.com:443 https://www.googletagmanager.com https://cdn-ukwest.onetrust.com https://geolocation.onetrust.com https://phoenixgroup-privacy.my.onetrust.com; frame-src *.risk.reassure.co.uk; font-src fonts.gstatic.com; img-src 'self' data: https://www.google-analytics.com https://www.googletagmanager.com https://cdn-ukwest.onetrust.com; object-src 'none'; default-src 'self';  style-src strict-dynamic 'unsafe-hashes' 'self' 'nonce-${newNonce}' 'sha256-e1C4j3BPnb0GihD2wZYrDYViZ8JQzV8UUU4zz5G9lac=' 'sha256-zTIOt7U/pPBOqRrCjc7KR/IZK6w7SfvpWFPhoLgVkwE=' 'sha256-uDFAT3aQ+rBcqGTPiCxp+N4k+JaeM+nsgINLyCDOpiM=' 'sha256-+k2xGRBbAnjfB0L/6v+ze82k+JS5YaD9YZrrBmKKiPk=' 'sha256-1sNDuLCPmujH/2mzeL8q/gqrYyCtYPmK7gTvAukcTOg=' 'sha256-Ifq6nek+fFTXn0xMBacolEVtiCVqyHxKqIxgFEkVQ38=' 'sha256-f8HkaZCZ0EO8c2L1aX93F91VPVA+pqcWBfv18HB0CrQ=' 'sha256-R0klTX5m20z8hJr450JboXf9cEdBjcdDxtO9CPvL0FY=' 'sha256-LF27Fcjb3zNwT1I7aLVlFcbNbTi/4tQcwhMYbHWqsJk=' 'sha256-0eNF0iQJFqvvjJ8yI/p2rfD11efImWwWAr0UDtx55B0=' 'sha256-Nl+Y0WnIDTIQhLEsE/OcoxFibw1+q7QKr6bUo3I0ahg=' 'sha256-olQGeplpTcejv5FKleYGUaM+g8hOYHNnOLi1nSTUIj0=' 'sha256-jne+Je1ywpxtLmQNtzZU1/0pgkE5Jfwx4hY39YIqbjU=' 'sha256-JN34oVk9jCYhnPdJff1gLu5SCQYQ7BbolG6xVY5GAus=' 'sha256-RPkEF5RATc8enbrH/yxMSabaPKzt+t3ge+QCE/ZSDyA=' cdn.cookielaw.org cookie-cdn.cookiepro.com privacyportal.onetrust.com geolocation.onetrust.com fonts.googleapis.com cdn-ukwest.onetrust.com; script-src 'self' 'nonce-${newNonce}' strict-dynamic *.risk.reassure.co.uk https://www.google-analytics.com https://www.googletagmanager.com https://cdn-ukwest.onetrust.com phoenixgroup-privacy.my.onetrust.com privacyportal.onetrust.com  https://cdn.cookielaw.org https://cookie-cdn.cookiepro.com`;
        document.head.insertBefore(cspMetaTag, document.head.firstChild);
    }, []);

    return <NonceContext.Provider value={newNonce}>{children}</NonceContext.Provider>;
};

export default NonceContext;
