import {
    STATE_INITIAL,
    STATE_RESOLVING,
    STATE_RESOLVED,
    STATE_ERROR,
} from './types';

export const isInitial = state => STATE_INITIAL === state.get('status');
export const isResolving = state => STATE_RESOLVING === state.get('status');
export const isResolved = state => !isResolving(state) && STATE_RESOLVED === state.get('status');
export const isError = state => !isResolving(state) && STATE_ERROR === state.get('status');