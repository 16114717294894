import graphQLQuery from '../../services/graphql/query';
import { isResolving, isResolved, } from '../../services/state/state';
import {
    QUESTIONS_REQUESTED,
    QUESTIONS_RESOLVED,
    QUESTIONS_ERROR,
} from './types';

/**
 * getQuestions action resolver
 * @description Populates the redux store with risk questions
 */
const getQuestions = async () => async (dispatch, getState) => {

    try {
        const state = getState().questions;
        if (isResolving(state) || isResolved(state)) return;

        dispatch({
            type: QUESTIONS_REQUESTED,
        })

        const payload = await graphQLQuery('getQuestions');

        return dispatch({
            type: QUESTIONS_RESOLVED,
            payload,
        })

    }
    catch (error) {
        return dispatch({
            type: QUESTIONS_ERROR,
            payload: error,
        })
    }
}

export default getQuestions;