import {
    QUESTIONNAIRE_START,
    QUESTIONNAIRE_UPDATE,
    QUESTIONNAIRE_ERROR,
} from '../../actions/questionnaire/types';
import {
    STATE_RESOLVED,
    STATE_ERROR,
} from '../../services/state/types';
import { RESET } from '../../actions/reset/types';
import schema from '../schema';

const initialState = schema;

/**
*
* The Questionnaire Reducer
*
* Responsible for managing the state and data of the risk questions
*
*/
const questionnaireReducer = (state = initialState, action) => {

    switch (action.type) {

        case RESET:

            return initialState;

        case QUESTIONNAIRE_START:

            return state
                .set('status', STATE_RESOLVED)
                .set('number', 1)
                .set('question', action.payload.question)
                .set('total', action.payload.total)

        case QUESTIONNAIRE_UPDATE:

            return state
                .set('question', action.payload.question)
                .set('number', action.payload.number)
        
        case QUESTIONNAIRE_ERROR:

            return state
                .set('status', STATE_ERROR)
                .set('error', action.payload)

        default:

            return state;
    }
};

export { initialState };
    
export default questionnaireReducer;
