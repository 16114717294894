import { theme as defaultTheme, pallette } from '@reassure/ui/core';

const { midGreen } = pallette;

const theme = {
    ...defaultTheme,
    colors: {
        ...defaultTheme.colors,
        interaction: midGreen,
    },
};

theme.colors.button.primary.disabled.background = theme.colors.background3;
theme.colors.modals.text = theme.colors.primary2;
theme.colors.modals.background = theme.colors.white;
theme.colors.modalText = theme.colors.primary2;
theme.colors.modalBackground = theme.colors.white;

export default theme;
