import { useContext, useEffect } from 'react';
import NonceContext from '../NonceContext';

const useNonce = () => {
    const nonce = useContext(NonceContext);
    return nonce;
};

export const useWaitForElement = (elementId, callback) => {
    useEffect(() => {
        const observer = new MutationObserver(mutations => {
            for (const mutation of mutations) {
                if (mutation.addedNodes) {
                    mutation.addedNodes.forEach(node => {
                        if (node.id === elementId) {
                            callback(node);
                        }
                    });
                }
            }
        });

        observer.observe(document.head, {
            childList: true,
            subtree: true,
        });

        return () => observer.disconnect();
    }, [elementId, callback]);
};

export default useNonce;
