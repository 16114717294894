import React from 'react';
import { LoadingDots } from '@reassure/ui/core';
import { PageLoadingWrapper } from './styles';

const PageLoading = () => (
    <PageLoadingWrapper>

        <LoadingDots/>

    </PageLoadingWrapper>
)

export default PageLoading;