import {
    RESULTS_REQUESTED,
    RESULTS_RESOLVED,
    RESULTS_ERROR,
    RESULTS_DEFINITION_RATING_UPDATE,
    RESULTS_RESET,
} from '../../actions/results/types';
import {
    STATE_RESOLVING,
    STATE_RESOLVED,
    STATE_ERROR,
} from '../../services/state/types';
import { RESET } from '../../actions/reset/types';
import schema from '../schema';

const initialState = schema
    .set('rating', null)
    .set('previousSelectedRating', null)
    .set('selectedRating', null)
    .set('definitions', null)
    .set('inflationRiskScale', null)
    .set('volatilityRiskScale', null)
    .set('returns', null)
    .set('purposes', null)

/**
*
* The Results Reducer
*
* Responsible for managing the state and data of the results
*
*/
const resultsReducer = (state = initialState, action) => {

    switch (action.type) {

        case RESET:
        case RESULTS_RESET:

            return initialState;

        case RESULTS_REQUESTED:

            return state
                .set('status', STATE_RESOLVING)

        case RESULTS_RESOLVED:

            return state
                .set('status', STATE_RESOLVED)
                .set('rating', action.payload.rating)
                .set('selectedRating', action.payload.rating)
                .set('definitions', action.payload.definitions)
                .set('inflationRiskScale', action.payload.inflationRiskScale)
                .set('volatilityRiskScale', action.payload.volatilityRiskScale)
                .set('returns', action.payload.returns)
                .set('purposes', action.payload.purposes)

        case RESULTS_ERROR:

            return state
                .set('status', STATE_ERROR)
                .set('rating', null)
                .set('error', action.payload)
        
        case RESULTS_DEFINITION_RATING_UPDATE:

            const selectedRating = state.get('selectedRating');
            const previousSelectedRating = state.get('previousSelectedRating');

            if (!previousSelectedRating || (action.payload !== selectedRating)) {
                state = state.set('previousSelectedRating', selectedRating)
            }

            return state
                .set('selectedRating', action.payload);

        default:

            return state;
    }
};

export { initialState };
    
export default resultsReducer;
