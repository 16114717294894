import { Map } from 'immutable';
import { STATE_INITIAL } from '../services/state/types';

export default Map(
    Object.assign(
        {},
        {
            status: STATE_INITIAL,
        }
    )
);
