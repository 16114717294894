/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestions = /* GraphQL */ `
  query GetQuestions {
    getQuestions {
      id
      text
      answers {
        id
        text
      }
    }
  }
`;
