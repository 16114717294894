/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://2n345kdzy5cndfecionwwy5jqy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_content_delivery_bucket": "rirp-web-app-20200225144707-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d26k370diwsl1n.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-west-1:f650025d-2f77-4937-8825-bf9256523eaa",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_i2O06PMTn",
    "aws_user_pools_web_client_id": "3lgq23tahkv4bnp83b8kuf84mp",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
