import { QUESTIONNAIRE_START, QUESTIONNAIRE_ERROR } from './types';
import { isInitial, isError } from '../../services/state/state';

const startQuestionnaire = async () => async (dispatch, getState) => {
    let questionsState = getState().questions;

    try {
        if (isInitial(questionsState) || isError(questionsState)) {
            throw new Error(`Questions uavailable`);
        }

        const questions = questionsState.get('questions');

        const payload = {
            question: questions[0],
            total: questions.length,
        };

        return dispatch({
            type: QUESTIONNAIRE_START,
            payload,
        });
    } catch (error) {
        return dispatch({
            type: QUESTIONNAIRE_ERROR,
            payload: error,
        });
    }
};

export default startQuestionnaire;
