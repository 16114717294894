import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import startQuestionnaire from '../actions/questionnaire/startQuestionnaire';
import getQuestions from '../actions/questions/getQuestions';
import { RESULTS_RESET } from '../actions/results/types';

export const useGoogleTagManager = () => {
    const [initialised, setInitialised] = useState(false);

    const [cookieProLoaded, setCookieProLoaded] = useState(false);

    const handleCookieProLoaded = useCallback(() => {
        setCookieProLoaded(true);
    }, []);

    useEffect(() => {
        window.addEventListener('rirp.cookiePro.loaded', handleCookieProLoaded);
        return () => {
            window.removeEventListener('rirp.cookiePro.loaded', handleCookieProLoaded);
        };
    }, [handleCookieProLoaded]);

    useEffect(() => {
        if (!process.env.REACT_APP_GTM_ACCOUNT || initialised) return;
        if (!initialised) {
            TagManager.initialize({
                gtmId: process.env.REACT_APP_GTM_ACCOUNT,
            });
            console.info(`Google Tag Manager being tracked to account ${process.env.REACT_APP_GTM_ACCOUNT}`);
            setInitialised(true);
        }
    }, [initialised, cookieProLoaded]);
};

export const useQuestionnaire = () => {
    const dispatch = useDispatch();

    const restartQuestionnaire = useCallback(async () => {
        dispatch({
            type: RESULTS_RESET,
        });
        await dispatch(await startQuestionnaire());
    }, [dispatch]);

    const initialiseQuestionnaire = useCallback(async () => {
        await dispatch(await getQuestions());
        await dispatch(await startQuestionnaire());
    }, [dispatch]);

    return {
        initialiseQuestionnaire,
        restartQuestionnaire,
    };
};
